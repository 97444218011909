<template lang="pug">
.separator-line(:class="directionClass" :style="style")
</template>

<script>
  export default {
    name: 'Separator',
    props: {
      direction: {
        type: String,
        default: () => 'vertical',
        validator: (value) => ['vertical', 'horizontal'].includes(value),
      },
      type: {
        type: String,
        default: () => 'solid',
        validator: (value) => ['dashed', 'solid'].includes(value),
      },
      color: {
        type: String,
        default: () => 'gray',
        validator: (value) => ['gray', 'orange'].includes(value),
      },
      width: {
        type: Number,
        default: () => 1,
        validator: (value) => value > 0,
      },
    },
    computed: {
      directionClass() {
        return {
          'separator-line--vertical': !this.direction || this.direction === 'vertical',
          'separator-line--horizontal': this.direction === 'horizontal',
          [`separator-line--${this.color || 'gray'}`]: true,
          [`separator-line--${this.type || 'solid'}`]: true,
        };
      },
      style() {
        return {
          'border-width': `${this.width}px`,
        };
      },
    },
  };
</script>

<style lang="sass">
  .separator-line
    writing-mode: vertical-rl
    text-orientation: upright
    &--vertical
      height: 100%
      border-color: transparent

      &.separator-line--gray
        border-left-color: #E3E5E8

      &.separator-line--orange
        border-left-color: #FFEFE5

    &--horizontal
      width: 100%
      border-color: transparent

      &.separator-line--gray
        border-top-color: #E3E5E8

      &.separator-line--orange
        border-top-color: #FFEFE5

    &--solid
      border-style: solid
    &--dashed
      border-style: dashed
</style>
